import React from 'react';
import './formulator.css'

function FormulationsTable() {
  const data = [
    { id: 1, name: 'Lipstick', type: 'Makeup', quantity: '20' },
    { id: 2, name: 'Foundation', type: 'Makeup', quantity: '15' },
    { id: 3, name: 'Eye shadow', type: 'Makeup', quantity: '30' },
    // Add more data here
  ];

  const tableData = [
    { lineType: 'Stage', description: 'STEP 1', quantity: '72.3351 Lb', unitCost: '15.0000/Lb', batchCost: 100 },
    { lineType: 'Raw', description: 'Acrylic Resin #224', quantity: '72.3351 Lb', unitCost: '15.0000/Lb',  batchCost: 100 },
    { lineType: 'Instr', description: 'Do X Y and then Z', quantity: '72.3351 Lb', unitCost: '15.0000/Lb',  batchCost: 100 },
    { lineType: 'Stage', description: 'STEP 2', quantity: '72.3351 Lb', unitCost: '15.0000/Lb',  batchCost: 100 },

    // ... more rows
  ];
  

  const getStyleForLineType = (lineType) => {
    switch (lineType) {
      case 'Stage':
        return { backgroundColor: '#d9e1f2', fontWeight: 'bold' };
      case 'Raw':
        return { backgroundColor: '#ffffff' };
      case 'Instr':
        return { backgroundColor: '#ffe599' };
      // ... other cases
      default:
        return {};
    }
  };
  
  return (
    <div>
        <center>
        <h2>Formulation</h2> 
        </center>
    <table>
      <thead>
        <tr>
          <th>Line Type</th>
          <th>Description</th>
          <th>Quantity</th>
          <th>Unit Cost</th>
          <th>Row Cost</th>
          {/* ... other headers */}
        </tr>
      </thead>
      <tbody>
        {tableData.map((row, index) => (
          <tr key={index} style={getStyleForLineType(row.lineType)}>
            <td>{row.lineType}</td>
            {row.lineType === 'Stage' || row.lineType === 'Instr' ? (
              <td colSpan="4">{row.description}</td> // Merges the next three columns
            ) : (
              <>
                <td>{row.description}</td>
                <td>{row.quantity}</td>
                <td>{row.unitCost}</td>
                <td>{row.batchCost}</td>
              </>
            )}
          </tr>
        ))}
      </tbody>
    </table></div>
  );
}

export default FormulationsTable;