import React, { useState } from 'react';
import FormulationsTable from './Formulations';
import RawMaterialsTable from './Raw_Materials';
import ProductFormulation from './productformulation';
import ChemDraw from './chemdraw';
import './App.css'

function App() {
  // Define state to determine which component to render
  const [cases, setCases] = useState('formulations');

  return (
    <div className="App">
      <center>
        <h1>G. Angelee Formulator</h1>
      </center>
      <div className='tablecontainer'>

      {/* Conditional rendering based on 'cases' state */}
      {cases === 'formulations' && <FormulationsTable />}
      {cases === 'products' && <ProductFormulation />}
      {cases === 'Raw Materials' && <RawMaterialsTable />}

      {/* Buttons to change the state and switch between cases */}
      <button onClick={() => setCases('formulations')}>Show Formulations</button>
      <button onClick={() => setCases('products')}>Show Products</button>
      <button onClick={() => setCases('Raw Materials')}>Show Raw Materials</button>
   </div>
    </div>
  );
}

export default App;
