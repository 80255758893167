import React from 'react';

function ProductTable() {
  // Dummy data for products
  const products = [
    { id: 1, name: 'Herbal Essence Shampoo', category: 'Shampoos' },
    { id: 2, name: 'Fruit Fusion Shampoo', category: 'Shampoos' },
    { id: 3, name: 'Moisture Rich Conditioner', category: 'Conditioners' },
    { id: 4, name: 'Curl Defining Conditioner', category: 'Conditioners' },
    { id: 4, name: 'Curl Defining Conditioner', category: 'Conditioners' },
    { id: 4, name: 'Curl Defining Conditioner', category: 'Conditioners' },
    { id: 4, name: 'test', category: 'Lotions' },
    // ... other products
  ];

  // Extracting categories from products data
  const categories = [...new Set(products.map((product) => product.category))];

  return (
    <div>
        <center>
      <h2>Product List</h2>
      </center>
      <table className='table-common products-table'>
        <thead>
          <tr>
            <th>Product Name</th>
            <th>Category</th>
          </tr>
        </thead>
        <tbody>
          {categories.map((category) => (
            <React.Fragment key={category}>
              <tr>
                <td style={{ fontWeight: 'bold', backgroundColor: '#eef' }} colSpan="2">{category}</td>
              </tr>
              {products
                .filter((product) => product.category === category)
                .map((product) => (
                  <tr key={product.id}>
                    <td>{product.name}</td>
                    <td>{product.category}</td>
                  </tr>
                ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ProductTable;
