import React from 'react';
import './formulator.css'

function RawMaterialsTable() {
  // Dummy data for ingredients
  const ingredients = [
    { id: 1, name: 'Aloe Vera Gel', category: 'Natural Extracts', quantity: '500ml' },
    { id: 2, name: 'Shea Butter', category: 'Butters & Waxes', quantity: '200g' },
    { id: 3, name: 'Argan Oil', category: 'Oils', quantity: '300ml' },
    { id: 4, name: 'Rosemary Extract', category: 'Natural Extracts', quantity: '100ml' },
    // ... other ingredients
  ];

  return (
    <div>
      <center>
      <h2>Raw Materials</h2>
      </center>
      <table className='table-common ingredients-table'>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Category</th>
            <th>Quantity</th>
          </tr>
        </thead>
        <tbody>
          {ingredients.map((ingredient) => (
            <tr key={ingredient.id}>
              <td>{ingredient.id}</td>
              <td>{ingredient.name}</td>
              <td>{ingredient.category}</td>
              <td>{ingredient.quantity}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default RawMaterialsTable;
